.footer {
    background: #000;
    position: relative;
    z-index: 0;
    overflow: hidden;
    padding: 7em 0 5em;
    @media (max-width: 768px) {
        padding: 50px 0px !important;
    }

    a {
        color: #000;
    }

    p {
        color: rgba(255, 255, 255, 1);
    }

    .copyright {
        color: rgba(255, 255, 255, 1);
    }

    .footer-heading {
        font-size: 18px;
        margin-bottom: 30px;
        font-weight: 800;
        color: #fff;
        text-transform: uppercase;
        position: relative;
    }

    ul.list-unstyled li a {
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 1);
    }

    .block-21 .img {
        display: block;
        height: 80px;
        width: 80px;
    }

    .block-21 .text {
        width: calc(100% - 100px);
    }

    .block-21 .text .heading {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
    }

    .block-21 .text .heading a {
        color: #fff;
    }

    .block-21 .text .heading a:hover,
    .block-21 .text .heading a:active,
    .block-21 .text .heading a:focus {
        text-decoration: none;
    }

    .block-21 .text .meta > div {
        display: inline-block;
        font-size: 12px;
        margin-right: 5px;
    }

    .block-21 .text .meta > div a {
        color: rgba(255, 255, 255, 1);
    }

    .block-23 ul li {
        color: rgba(255, 255, 255, 1);
    }

    .block-23 ul li a {
        color: rgba(255, 255, 255, 1);
    }

    .block-23 ul li .icon {
        color: rgba(255, 255, 255, 1);
    }

    .ftco-footer-social li a {
        background: rgba(255, 255, 255, 1);
        color: #000;
    }
}
