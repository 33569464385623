// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";




.sermons {
    // .overlay {
    //     opacity: .7 !important;
    //     background: #000 !important;
    // }

    .overlay {
        opacity: 0.7 !important;
        background-image: repeating-linear-gradient(135deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),repeating-linear-gradient(45deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),linear-gradient(90deg, rgb(125, 17, 100),rgb(4, 15, 71)) !important;
    }

    .hero-wrap {
        background: url('../../assets/gallery/ThanksgivingService/compressed/34.webp');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        .btn-main {
            background: #F8B44D;
            background: linear-gradient(180deg, #F8B44D 0%, #d28f2b 100%);
            border: none;
            font-size: 17px;
            padding: 16px 24px;
            color: #000;
            transition: all .3s ease-in !important;
            transition: none !important;
            &:hover {
                border-color: #d28f2b !important;
                background: #fff;
                color: #000;
            }
        }
    }

    .hero-wrap, .slider-text {
        min-height: 710px !important;
        background-position: center center !important;

        @media (max-width: 979px) {
            min-height: 410px !important;
        }
    }
    
    .bg-section1 {
        padding: 60px 0px !important;
        background: #F1EEE6;
    }

    .btn-sermon-outline {
        font-size: 16px;
        text-transform: none;
        font-style: italic;
        font-weight: 400;
        text-decoration: underline;
    }

    .sermon-item {
        margin-bottom: 70px;
        width: 100%;
        border-radius: 0px 0px 8px 8px;
        box-shadow: rgba(124, 124, 124, 0.14) 0px 3px 8px;

        .yt-lite {
            border-radius: 12px;
            border-radius: 8px 8px 0px 0px;
        }

        .img {
            width: 100%;
            display: block;
            height: 150px;
        }

        .text {
            width: 100%;
            padding: 30px 30px;
            background: #fff;
            border-radius: 0px 0px 8px 8px;
            h2 {
                font-size: 18px;
                font-weight: 500;
                color: #000000;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 8px;
            }
        }
    }

    button.btn-main {
        background: #000;
        margin-top: 20px;
        background: linear-gradient(180deg, #333 0%, #000 100%);
        background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
        border: 2px solid #1c589c;
        padding: 14px 25px;
        font-size: 15px;
        line-height: 27px;
        text-transform: none;
        font-weight: 500 !important;
        color: #fff;
        transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
        &:hover {
            border-color: #1c589c;
            background: #fff;
            color: #1c589c;
        }
    }

    .cta {
        background: linear-gradient(rgba(2, 14, 4, 0.35), rgba(0, 0, 0, 0.87)) center center / cover, url('../../assets/images/compressed/7.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 72px 0px;

        h2 {
            color: #fff;
            font-size: 48px;
            margin-bottom: 20px;
        }

        p {
            color: #fff;
            font-size: 20px;
            margin-bottom: 56px;
            line-height: 32px;
        }
    }

    @media (max-width: 767.98px) {
        .sermon-item {
            margin-bottom: 60px;
        }
    }

    @media (max-width: 767.98px) {
        .sermon-item .img {
            width: 100%;
            height: 300px;
        }
    }

    @media (max-width: 767.98px) {
        .sermon-item .text {
            width: 100%;
        }
    }
}
