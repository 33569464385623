@import "./variables";
@import "./mixins";

.container {
    @media (max-width: 767px) { 
        padding-left: 30px;
        padding-right: 30px;
    }
}

.gallery-section {
    padding: 90px 0px;
    background: #fff;

    // span.subheading {
    //     color: #f2f2f2;
    // }
    // h2 {
    //     color: #fff;
    // }
}

.join-ministry-form {
    .ant-modal-content {
        background: fff;
        .ant-modal-header {
            .ant-modal-title {
                color: #000;
                font-size: 24px;
            }
        }
        .ant-modal-body {
            padding: 20px 0px 20px;
            .form-group {
                // margin-bottom: 0px;
            }
            label {
                color: #000 !important;
            }
            input {
                border: 1px solid rgba(97, 110, 124, 0.25);
                border-radius: 12px;
                padding: 20px;
                &:hover, &:focus {
                    border: 1px solid rgb(54, 101, 176) !important;
                }
            }
            select {
                border: 1px solid rgba(97, 110, 124, 0.25);
                border-radius: 12px;
                &:hover, &:focus {
                    border: 1px solid rgb(54, 101, 176) !important;
                }
            }
            textarea {
                border: 1px solid rgba(97, 110, 124, 0.25);
                border-radius: 12px;
                &:hover, &:focus {
                    border: 1px solid rgb(54, 101, 176) !important;
                }
            }
            .btn-main {
                width: 100%;
                margin-top: 40px;
                width: 100%;
                background: #000;
                background: linear-gradient(180deg, rgb(54, 101, 176) 0%, rgb(30, 74, 143) 100%);
                border: 2px solid rgb(54, 101, 176);
                padding: 14px 25px;
                font-size: 16px;
                text-transform: none;
                font-weight: 400 !important;
                color: #fff;
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
                &:hover {
                    background: transparent;
                    color: rgb(54, 101, 176);
                }
            }

            .btn-main-black {
                width: 100%;
                margin-top: 40px;
                background: #000;
                background: linear-gradient(180deg, #333 0%, #000 100%);
                border: 2px solid #000;
                padding: 14px 25px;
                font-size: 16px;
                text-transform: none;
                font-weight: 400 !important;
                color: #fff;
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
                &:hover {
                    background: #fff;
                    color: #000;
                }
            }
        }
    }
}

.d-flex {
    display: flex;
}

.yt-lite {
    border-radius: 12px;
}

.btn-transparent-outline {
    padding: 16px 24px;
    min-width: 150px;
    background: transparent;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
    color: #fff;
    border-radius: 12px;
    border: 1px solid #fff;

    &:hover {
        background: #fff;
        border: 1px solid #fff;
        color: #000;
        transition: all 0.2s linear;
    }
}


.atcb-initialized {
    .atcb-button-wrapper {
        background-color: red;
        padding: 0px !important;
    
        .atcb-button {
            box-shadow: none !important;
        }
    }
}

// .atcb-light {
//     background: red !important;
// }

// #calendar-button {
//     background: red !important;
// }
  

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
  
  /* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
    [data-aos] {
      pointer-events: auto !important;
      opacity: 1 !important;
      transform: none !important;
    }
}