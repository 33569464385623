.btns {
    &:focus,
    &:hover {
        box-shadow: none !important;
    }
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    color: #000000 !important;

    img {
        width: 150px !important;
        // width: 30%;
    }
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .dropdown-menu {
        position: static;
        float: none;
    }
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.bg-transparent {
    background: transparent !important;
    transition: 0.4s all ease;

    .navbar-brand {
        color: #fff;
    }

    .navbar-nav > .nav-item > .nav-link {
        color: #f2f2f2;
    }

    .navbar-nav > .nav-item > .nav-link:hover {
        color: #fff;
    }

    @media (max-width: 991.98px) {
        .navbar-nav > .nav-item > .nav-link:hover {
            color: #000;
        }
        .navbar-nav > .nav-item > .nav-link {
            font-size: 16px;
            color: #000;
        }
    }

    .navbar-nav > .nav-item.active > a {
        color: #fff;
    }

    @media (max-width: 991.98px) {
        .navbar-nav > .nav-item.active > a {
            color: #000;
        }
    }

    .navbar-nav > .nav-item.ftco-seperator:before {
        background: #d5d5d5;
    }
}

.bg-white {
    background: #fff !important;
    transition: 0.4s all ease;
    z-index: 10;

    .navbar-brand {
        color: #000;
    }

    .navbar-nav > .nav-item > .nav-link {
        font-weight: 400;
        color: #000;
        text-transform: uppercase;
    }

    .navbar-nav > .nav-item > .nav-link:hover {
        color: #000;
    }

    @media (max-width: 991.98px) {
        .navbar-nav > .nav-item > .nav-link {
            color: #000;
        }
    }

    .navbar-nav > .nav-item.active > a {
        color: #000;
    }

    @media (max-width: 991.98px) {
        .navbar-nav > .nav-item.active > a {
            color: #000;
        }
    }

    .navbar-nav > .nav-item.ftco-seperator:before {
        background: #d5d5d5;
    }
}

.ftco-navbar-light {
    padding: 10px 40px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    box-shadow: 0 5px 20px 0px rgba(0,0,0,0.1);

    @media (max-width: 991.98px) {
        background: #fff !important;
        position: relative;
        top: 0;
    }

    @media (max-width: 991.98px) {
        .navbar-brand {
            color: #000;
        }
    }

    @media (max-width: 991.98px) {
        .navbar-nav {
            padding-bottom: 10px;
        }
    }

    .navbar-nav > .nav-item > .nav-link {
        font-size: 15px;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        padding-left: 20px;
        text-transform: capitalize;
        padding-right: 15px;
        font-weight: 500 !important;
        cursor: pointer;
        letter-spacing: 0px;
        opacity: 1 !important;
        &:hover {
            color: #1c589c;
        }
    }

    @media (max-width: 991.98px) {
        .navbar-nav > .nav-item > .nav-link {
            font-size: 16px;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }

    .navbar-nav > .nav-item .dropdown-menu {
        border: none;
        background: #000;
        -webkit-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
        -moz-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
        box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
    }

    .navbar-nav > .nav-item.ftco-seperator {
        position: relative;
        margin-left: 20px;
        padding-left: 20px;
    }

    @media (max-width: 991.98px) {
        .navbar-nav > .nav-item.ftco-seperator {
            padding-left: 0;
            margin-left: 0;
        }
    }

    .navbar-nav > .nav-item.ftco-seperator:before {
        position: absolute;
        content: "";
        top: 10px;
        bottom: 10px;
        left: 0;
        width: 2px;
    }

    @media (max-width: 991.98px) {
        .navbar-nav > .nav-item.ftco-seperator:before {
            display: none;
        }
    }

    .navbar-nav > .nav-item.cta > a {
        color: #fff;
        border: 1px solid #000;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 18px;
        padding-right: 18px;
        margin-top: 4px;
        background: #000;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        border-radius: 12px;
        cursor: pointer;
    }

    .navbar-nav > .nav-item.cta > a span {
        display: inline-block;
        color: #fff;
    }

    .navbar-nav > .nav-item.cta > a:hover {
        background: #000;
        border: 1px solid #000;
    }

    .navbar-nav > .nav-item.cta.cta-colored a {
        border: 1px solid #000;
        background: #000 !important;
    }

    .navbar-toggler {
        border: none;
        // color: rgba(255, 255, 255, 0.5) !important;
        color: #000 !important;
        cursor: pointer;
        padding-right: 0;
        text-transform: uppercase;
        font-size: 22px;
        letter-spacing: 0.1em;
    }

    .navbar-toggler:hover,
    .navbar-toggler:focus {
        text-decoration: none;
        color: #000;
        outline: none !important;
    }

    .scrolled {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        margin-top: -130px;
        background: #fff !important;
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 991.98px) {
        .scrolled .nav-item > .nav-link {
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .scrolled .nav-item.active > a {
        color: #000 !important;
    }

    .scrolled .nav-item.cta > a {
        color: #fff !important;
        background: #000;
        border: none !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        margin-top: 6px !important;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
    }

    .scrolled .nav-item.cta > a span {
        display: inline-block;
        color: #fff !important;
    }

    .scrolled .nav-item.cta.cta-colored span {
        border-color: #000;
    }

    @media (max-width: 991.98px) {
        .scrolled .navbar-nav {
            background: none;
            border-radius: 0px;
            padding-left: 0rem !important;
            padding-right: 0rem !important;
        }
    }

    .scrolled .navbar-toggler {
        border: none;
        color: rgba(0, 0, 0, 0.5) !important;
        border-color: rgba(0, 0, 0, 0.5) !important;
        cursor: pointer;
        padding-right: 0;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 0.1em;
    }

    .scrolled .nav-link {
        padding-top: 0.9rem !important;
        padding-bottom: 0.9rem !important;
        color: #000000 !important;
    }

    .scrolled .nav-link.active {
        color: #000 !important;
    }

    .scrolled.awake {
        margin-top: 0px;
        -webkit-transition: 0.3s all ease-out;
        -o-transition: 0.3s all ease-out;
        transition: 0.3s all ease-out;
    }

    .scrolled.sleep {
        -webkit-transition: 0.3s all ease-out;
        -o-transition: 0.3s all ease-out;
        transition: 0.3s all ease-out;
    }

    .scrolled .navbar-brand {
        color: #000000;
    }
}
