// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";



.events {
    // .overlay {
    //     opacity: .7 !important;
    //     background: #000 !important;
    // }

    .overlay {
        opacity: 0.7 !important;
        background-image: repeating-linear-gradient(135deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),repeating-linear-gradient(45deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),linear-gradient(90deg, rgb(125, 17, 100),rgb(4, 15, 71)) !important;
    }

    .hero-wrap, .slider-text {
        min-height: 710px !important;
        background-position: center center !important;

        @media (max-width: 979px) {
            min-height: 410px !important;
        }
    }

    
    .upcoming-event {
        padding: 60px 0px;
        .event-item {
            // border: 1px solid #f2f2f2;
            // box-shadow: rgba(124, 124, 124, 0.14) 0px 3px 8px;
            border-radius: 12px;
            .text {
                padding: 30px;
            }
            box-shadow: rgba(124, 124, 124, 0.14) 0px 3px 8px;
            margin-bottom: 50px;
            .h-full {
                height: 100%;
            }
            img {
                border-radius: 12px 12px 0px 0px;
                height: 100% !important;
                width: 100%;
                max-height: 300px;
                object-fit: cover;
            }

            h5 {
                color: #000 !important;
                font-size: 20px !important;
                font-weight: 600;
                line-height: normal;
            }

            a {
                text-decoration: none;
                border: 1.5px solid #333;
                color: #333;
                text-transform: none;
                font-weight: 400;
                width: 100%;
                font-size: 14px;
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
                &:hover {
                    background: #000;
                    border: 1.5px solid #000;
                    color: #fff;
                }
            }
        }
    }
}