// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";



.bg-section1 {
    background: #f2f2f2;
    padding: 100px 0px !important;
}

.contact {
    .overlay {
        opacity: .4 !important;
        background-image: repeating-linear-gradient(135deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),repeating-linear-gradient(45deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),linear-gradient(90deg, rgb(125, 17, 100),rgb(4, 15, 71)) !important;
    }

    .hero-wrap {
        // background: url('../../assets/images/contact.png');
        background: url('https://www.cloud4c.com/sites/default/files/2022-07/cloud4c-company-contact-1Desktop.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .hero-wrap, .slider-text {
        min-height: 500px !important;
        background-position: center center;
        @media (max-width: 979px) {
            min-height: 410px !important;
        }
    }

    
    .main-contact {
        padding: 90px 0px;
        background: url('../../assets/images/compressed/12.webp');
        background: #F1EEE6;
        background-size: cover;
        background-repeat: no-repeat;

        .icon-blue {
            background: #4251a3;
        }
        .icon-purple {
            background: #441475;
        }
        .icon-red {
            background: #a61b24;
        }
        .icon-yellow {
            background: #f8d562;
        }
        a {
            color: #333;
        }
    }

    .contact-wrap {
        border-radius: 12px;
        background-color: #000;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background-repeat: no-repeat;
        background-size: cover;

        input, textarea {
            padding: 20px;
        }

        button[type="submit"] {
            margin-top: 30px;
            padding: 14px 25px;
            text-transform: none;
            font-size: 14px;
            font-weight: 400;
            background: #fff;
            border-color: #fff;
            color: #000;
            &:hover {
                background: #000;
                color: #fff;
            }
        }
    }
}
