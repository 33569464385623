// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";




.payments {
    .overlay {
        opacity: .7 !important;
        background: #000 !important;
    }

    .hero-wrap {
        background-image: url('../../assets/images/compressed/5.webp') !important;
        background-size: cover;
        background-position: top center;
        min-height: 100vh;
    }

    .hero-wrap, .slider-text {
        min-height: 710px !important;
        background-position: center center !important;

        @media (max-width: 979px) {
            min-height: 410px !important;
        }
    }
    
    .bg-section1 {
        padding: 260px 0px 60px !important;
        background: #FAFAFA;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background-size: cover;
        background-repeat: no-repeat;

        p {
            font-size: 16px;
        }
    }

    .payment-item {
        margin-bottom: 30px;
        width: 100%;
        background-color: #fff;
        border-radius: 12px;
        padding: 40px;

        .text {
            width: 100%;

            .icon {
                margin-bottom: 16px;
                img {
                    width: 50px;
                    height: 50px;
                }
            }

            h2 {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 8px;
                line-height: normal;
                color: #441475;
            }

            p {
                color: rgba(18, 18, 18, 0.85);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
            }

            a, button {
                font-size: 15px;
                text-transform: none;
                background: #441475;
                color: #fff;
                padding: 10px 25px;
                font-weight: 400 !important;
            }

            .account-details, .address {
                margin-top: 25px;
                border-top: 1px solid #f2f2f2;
                padding-top: 20px;
                color: rgba(18, 18, 18, 0.85);
            }
        }
    }

    .faq {
        background: #FAFAFA;
        padding: 72px 0px 72px;
        
        h1 {
            font-family: 'HW Nuckle Trial';
            font-style: normal;
            font-weight: 700;
            text-align: center;
            text-transform: capitalize;
            line-height: 48px;
            font-size: 36px;
            color: #1D3451;
            margin-bottom: 8px;
            @media (max-width: 500px) { 
                font-size: 35px;
            }
        }
        p {
            line-height: 125%;
            color: #fff;
            @media (max-width: 500px) { 
                margin-bottom: 60px;
                font-size: 16px;
            }
        }
        .faq-item {
            // margin-bottom: 16px;
            cursor: pointer;
            .faq-content {
                padding: 32px 0px;
                text-align: left;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.60);;
                @media (max-width: 500px) { 
                    padding: 20px;
                }
                p.question {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 150%;
                    color: #000;
                    text-align: left;
                    display: flex;
                    margin-bottom: 0px;
                    justify-content: space-between;
                    color: #1D3451;

                    img {
                        margin-left: 10px;
                    }
                    @media (max-width: 500px) { 
                        font-size: 18px;
                    }
                }
                p.answer {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 180%;
                    color: rgba(18, 18, 18, 0.60);
                    text-align: left;
                    margin-bottom: 0px;
                    margin-top: 15px;
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        .sermon-item {
            margin-bottom: 60px;
        }
    }

    @media (max-width: 767.98px) {
        .sermon-item .img {
            width: 100%;
            height: 300px;
        }
    }

    @media (max-width: 767.98px) {
        .sermon-item .text {
            width: 100%;
        }
    }
}
