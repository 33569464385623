/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Hero New Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Regular'), url('Hero New Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Regular Italic'), url('Hero New Regular Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Hairline';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Hairline'), url('Hero New Hairline.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Hairline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Hairline Italic'), url('Hero New Hairline Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Thin'), url('Hero New Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Thin Italic'), url('Hero New Thin Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New UltraLight'), url('Hero New UltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New UltraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New UltraLight Italic'), url('Hero New UltraLight Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Light';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Light'), url('Hero New Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Light Italic'), url('Hero New Light Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Medium'), url('Hero New Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Medium Italic'), url('Hero New Medium Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New SemiBold'), url('Hero New SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New SemiBold Italic'), url('Hero New SemiBold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Bold'), url('Hero New Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Bold Italic'), url('Hero New Bold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New ExtraBold'), url('Hero New ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New ExtraBold Italic'), url('Hero New ExtraBold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Super';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Super'), url('Hero New Super.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Hero New Super Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Super Italic'), url('Hero New Super Italic.woff') format('woff');
    }