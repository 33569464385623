.countdown {
    display: flex;
    gap: 30px;

    div {
        text-align: center;
    }
    
    h3 {
        color: #fff;
        margin-bottom: 0px;
        font-weight: 500;
    }
    p {
        text-transform: uppercase;
        font-size: 12px;
    }
}

.event-countdown {
    position: absolute;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(15px);
    width: 100%;
    padding: 15px 0px;
    z-index: 1;

    @media (max-width: 560px) {
        display: none;
    }
    h5 {
        margin-bottom: 0px;
        color: #E6E6E6;
    }

    p {
        margin-bottom: 0px;
        color: #E6E6E6;
        font-size: 12px;
    }

    button.btn-view-all-events {
        margin-bottom: 0px;
        border-radius: 50px;
        border: 1px solid #fff;
        background: transparent;
        text-transform: none;
        color: #fff;
        font-weight: 400;
        cursor: pointer;
        font-size: 14px;
        padding: 10px 24px !important;
        &:hover {
            background: #fff;
            color: #000;
            transition: all 0.2s linear;
        }
    }
}