// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/GatewaySignature/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";
@import "../../assets/fonts/gibson/stylesheet.css";

@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: visible !important;
        padding: 0px 30px;
    }

    .container-fluid {
        padding: 0px 0px;
    }
}

.home {

    .overlay {
        opacity: 0.7 !important;
        // background: rgb(0, 0, 0) !important;
        background: linear-gradient(to bottom, rgba(16, 16, 16, 0.82), rgba(29, 1, 22, 0.83)) !important;
        background: linear-gradient(to bottom, #008793, #004d7a, #051937) !important;
        background: radial-gradient(circle at 85% 1%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 96%,transparent 96%, transparent 100%),radial-gradient(circle at 14% 15%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 1%,transparent 1%, transparent 100%),radial-gradient(circle at 60% 90%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 20%,transparent 20%, transparent 100%),radial-gradient(circle at 79% 7%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 78%,transparent 78%, transparent 100%),radial-gradient(circle at 55% 65%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 82%,transparent 82%, transparent 100%),linear-gradient(135deg, rgb(37, 56, 222),rgb(96, 189, 244)) !important;
        background-image: repeating-linear-gradient(135deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),repeating-linear-gradient(45deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),linear-gradient(90deg, rgb(125, 17, 100),rgb(4, 15, 71)) !important;
    }

    .home-slider {
        .btn-main {
            background: #F8B44D;
            background: linear-gradient(180deg, #F8B44D 0%, #d28f2b 100%);
            border: none;
            font-size: 17px;
            transition: all .3s ease-in !important;
            transition: none !important;
            &:hover {
                border-color: #d28f2b !important;
                background: #fff;
                color: #000;
            }
        }
    }

    .hero-wrap {
        @media (max-width: 979px) {
            min-height: 610px !important;
            height: 610px !important;
        }
    }

    .hero-wrap .home-slider {
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: top center !important;

        width: 100%;
        min-height: 100vh;
        .slider-item {
            background-position: top center;

            @media (max-width: 979px) {
                h1 {
                    margin-top: 70px;
                }

                p {
                    font-size: 20px;
                }
            }
        }
        
    }

    .home-slider .slider-item .slider-text {
        margin-top: -50px !important;
    }

    .swiper-button-prev, .swiper-button-next {
        &::after {
            color: #fff;
        }
    }

    .initiatives {
        background: #f2ead1;
        padding: 100px 0px !important;

        p {
            font-size: 18px;
            color: #000;
        }

        .initiative-item {
            padding: 64px 30px;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            text-align: center;
            margin-bottom: 30px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            h4 {
                font-weight: 600;
                color: #fff;
            }

            p {
                font-size: 14px;
                color: #fff;
            }

            a {
                text-decoration: underline;
                color: #fff;
            }
        }
    }
    
    .menu-list {
        padding: 40px;
    
        .menu-list-item {
            background: #fff;
            border-radius: 12px;
            padding: 30px 30px;
            color: #000;
            cursor: pointer;
            margin-bottom: 30px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
    
            &:hover {
                background: #000000;
    
                h4 {
                    color: #fff;
                    text-decoration: underline;
                }
    
                span, p {
                    color: #fff;
                }
    
                img {
                    -webkit-filter: invert(100%); /* Safari/Chrome */
                    filter: invert(100%);
                }
            }
    
            .icon {
                color: #000;
                margin-bottom: 12px;
            }
    
            h4 {
                color: #fff;
                color: rgb(242, 91, 91);
                color: #B30B37;
                font-size: 18px !important;
                text-transform: none;
                text-decoration: none;
                cursor: pointer;
            }
    
            span.subheadings {
                color: rgb(242, 91, 91);
                font-size: 14px;
                font-family: 'Inter';
                text-decoration: underline;
                cursor: pointer;
            }
    
            p {
                margin-bottom: 0px;
            }
    
            span {
                color: #fff;
                color: #000;
            }
        }
    }
      
    .senior-pastor {
        background: url('../../assets/images/compressed/12.webp');
        background: #000;
        background: rgb(17, 17, 17);
        background: #f2f2f2;
        background: #fff;
        background: #F2EEE6;
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        padding: 100px 0px;

        .img-frame {
            // border: 1px solid rgba(39, 46, 80, 0.50);
            // border: 2px solid #fff;
            // border: 4px solid #000;
            background-color: #fff;
            position: relative;
            border-radius: 12px;
            padding: 2px;
            padding: 0px;
            margin-bottom: 30px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            // box-shadow: 0 4.5810809135px 22.9054050446px 0 #a4abb71f, 0 12.2162160873px 18.3243236542px 0 #6b738424, 0 6.1081080437px 7.6351351738px 0 #3e405433;
        }
        img {
            border-radius: 8px;
        }
        span.subheading {
            // color: #f2f2f2;
            color: #000;
            text-transform: none;
            font-size: 22px;
            font-family: Gatteway Signature;
        }

        p {
            font-size: 17px;
            font-weight: 400;
            span {
                font-style: italic;
            }
        }

        .btn-main {
            background: #000;
            background: linear-gradient(180deg, #EE363B 0%, #af1217 100%);
            background: linear-gradient(180deg, #333 0%, #000 100%);
            background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
            border: 2px solid #1c589c;
            padding: 14px 25px;
            font-weight: 500 !important;
            color: #fff;
            transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
            &:hover {
                border-color: #1c589c;
                background: #fff;
                color: #1c589c;
            }
        }
    }

    .connecting {
        padding: 100px 0px;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) bottom center / cover,  url('https://s3.amazonaws.com/foursquare.org/wp-content/uploads/2022/12/09093835/FSQ_Web_Calling.jpeg');
        background: #000;
        background: rgb(17, 17, 17);
        background: #fff;
        background: #666565;
        background: url('../../assets/images/compressed/12.webp');
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background-image: linear-gradient(45deg, rgba(17, 133, 139, 0.26) 0%, rgba(17, 133, 139, 0.26) 16%,rgba(49, 138, 124, 0.26) 16%, rgba(49, 138, 124, 0.26) 30%,rgba(80, 142, 109, 0.26) 30%, rgba(80, 142, 109, 0.26) 51%,rgba(112, 147, 94, 0.26) 51%, rgba(112, 147, 94, 0.26) 66%,rgba(143, 152, 79, 0.26) 66%, rgba(143, 152, 79, 0.26) 77%,rgba(175, 156, 64, 0.26) 77%, rgba(175, 156, 64, 0.26) 98%,rgba(206, 161, 49, 0.26) 98%, rgba(206, 161, 49, 0.26) 100%),linear-gradient(135deg, rgba(3, 50, 197, 0.26) 0%, rgba(3, 50, 197, 0.26) 11%,rgba(19, 69, 202, 0.26) 11%, rgba(19, 69, 202, 0.26) 16%,rgba(34, 88, 207, 0.26) 16%, rgba(34, 88, 207, 0.26) 47%,rgba(50, 107, 213, 0.26) 47%, rgba(50, 107, 213, 0.26) 50%,rgba(66, 126, 218, 0.26) 50%, rgba(66, 126, 218, 0.26) 62%,rgba(81, 145, 223, 0.26) 62%, rgba(81, 145, 223, 0.26) 81%,rgba(97, 164, 228, 0.26) 81%, rgba(97, 164, 228, 0.26) 100%),linear-gradient(90deg, rgb(155, 9, 167) 0%, rgb(155, 9, 167) 41%,rgb(168, 14, 156) 41%, rgb(168, 14, 156) 52%,rgb(182, 18, 144) 52%, rgb(182, 18, 144) 65%,rgb(195, 23, 133) 65%, rgb(195, 23, 133) 75%,rgb(208, 28, 121) 75%, rgb(208, 28, 121) 82%,rgb(222, 32, 110) 82%, rgb(222, 32, 110) 87%,rgb(235, 37, 98) 87%, rgb(235, 37, 98) 100%);
        background-repeat: no-repeat;
        background-size: cover;

        .subheading {
            color: #fff;
        }

        h2 {
            color: #fff;
            font-size: 36px;
            margin-bottom: 16px;
        }

        p {
            color: #fff;
            font-size: 16px;
            margin-bottom: 40px;
        }
        .heading-section h2 {
            margin-bottom: 80px;
        }

        .connecting-item {
            margin-bottom: 30px;
            padding: 30px;
            background: #fff;
            border-radius: 12px;
            box-shadow: rgba(124, 124, 124, 0.24) 0px 3px 8px;
            i {
                font-size: 30px;
                margin-bottom: 0px;
                color: #000;
            }
            h4 {
                font-size: 20px;
                font-weight: 600;
                color: #000;
                margin-bottom: 0px;
            }

            p {
                font-size: 15px;
                margin-bottom: 10px;
                color: #000;
            }
            button, a {
                color: #fff;
                background: #000;
                background: linear-gradient(180deg, #333 0%, #000 100%);
                margin-top: 20px;
                border: 2px solid #000;
                outline: none;
                padding: 10px 15px;
                width: 100%;
                display: block;
                text-align: center;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                i {
                    font-size: 16px;
                    color: #fff;
                }
                
                &:hover {
                    background: #fff;
                    color: #000;
                    i {
                        color: #000;
                    }
                }
            }
        }

    }

    .services2 {
        padding: 8px 4px;
        .services-item {
            padding: 0px;
            background: #000;
            border: 8px solid #fff;
            border-radius: 18px;
            .content-container {
                background-size: cover;
                background-position: center center;
                padding: 100px 40px;
                min-height: 500px;
                border-radius: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background-repeat: no-repeat !important;
                .overlays {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    content: '';
                    opacity: .45;
                    border-radius: 12px;
                    background: #fff;
                    background: #000;
                }
                .content {
                    z-index: 1;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 12px;
                    h6 {
                        margin-top: 90px;
                        color: #fff;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 125%;
                    }
        
                    p {
                        color: #fff;
                        font-size: 16px;
                        font-style: italic;
                        margin-bottom: 0px;
                    }
        
                    p.schedule {
                        font-size: 16px;
                        margin-bottom: 26px;
                    }
                }
            }
        }
    }

    .services {
        padding: 90px 30px;
        background: #EE363B;
        background: #000;
        .heading-section {
            margin-bottom: 50px;
        }
        h2 {
            font-size: 36px;
            font-weight: 600 !important;
            color: #fff;
        }
        a {
            color: #fff;
            text-decoration: underline;
            cursor: pointer;
        }
        span.subheading {
            color: #fff !important;
            margin-bottom: 0px;
        }
        .services-item {
            margin-bottom: 30px;
            background: #000;
            display: flex;
            flex-direction: column;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 20px;
            border-radius: 12px;
            position: inherit;
            background-position: center center;
            width: 100%;
            // overflow: hidden;
            box-shadow: 0 1px 4px rgba(24, 29, 54, 0.16);

            .overlays {
                position: absolute;
                width: 100%;
                top: 0;
                right: 0;
                left: 0;
                border-radius: 12px;
                bottom: 0;
                content: '';
                background: #000;
                background: #fff;
                background-size: cover;
                background-repeat: no-repeat;
                
            }
            .content {
                z-index: 1;
            }

            img {
                height: 250px;
                width: 100%;
                object-fit: cover;
                margin-bottom: 20px;
                border-radius: 12px;
            }

            .icon {
                font-size: 40px;
                color: #000;
            }
            h6 {
                color: #000;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
            }

            p {
                color: #000;
                font-size: 15px;
                margin-bottom: 0px;
            }

            p.schedule {
                font-size: 16px;
                margin-bottom: 10px;
            }

            a {
                text-decoration: none;
                border: 1.5px solid #333;
                color: #333;
                text-transform: none;
                font-weight: 400;
                font-size: 14px;
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
                &:hover {
                    background: #000;
                    border: 1.5px solid #000;
                    color: #fff;
                }
            }
        }
    }

    .testimony-section {
        padding: 100px 0px 120px;
        background: #A92A74;
        background: #000;
        background: #F1EEE6;
        background-image: linear-gradient(45deg, rgba(183, 110, 7, 0.2) 0%, rgba(183, 110, 7, 0.2) 14%,rgba(148, 103, 21, 0.2) 14%, rgba(148, 103, 21, 0.2) 53%,rgba(113, 96, 34, 0.2) 53%, rgba(113, 96, 34, 0.2) 68%,rgba(79, 90, 48, 0.2) 68%, rgba(79, 90, 48, 0.2) 84%,rgba(44, 83, 61, 0.2) 84%, rgba(44, 83, 61, 0.2) 89%,rgba(9, 76, 75, 0.2) 89%, rgba(9, 76, 75, 0.2) 100%),linear-gradient(135deg, rgba(45, 53, 64, 0.2) 0%, rgba(45, 53, 64, 0.2) 20%,rgba(79, 76, 60, 0.2) 20%, rgba(79, 76, 60, 0.2) 38%,rgba(113, 99, 55, 0.2) 38%, rgba(113, 99, 55, 0.2) 42%,rgba(147, 122, 51, 0.2) 42%, rgba(147, 122, 51, 0.2) 46%,rgba(181, 145, 46, 0.2) 46%, rgba(181, 145, 46, 0.2) 66%,rgba(215, 168, 42, 0.2) 66%, rgba(215, 168, 42, 0.2) 100%),linear-gradient(90deg, rgb(176, 12, 74) 0%, rgb(176, 12, 74) 23%,rgb(190, 31, 76) 23%, rgb(190, 31, 76) 36%,rgb(205, 51, 78) 36%, rgb(205, 51, 78) 41%,rgb(219, 70, 81) 41%, rgb(219, 70, 81) 98%,rgb(234, 90, 83) 98%, rgb(234, 90, 83) 99%,rgb(248, 109, 85) 99%, rgb(248, 109, 85) 100%);

        span.subheading {
            // color: #000;
        }

        p {
            font-size: 18px;
        }
        
        .testimony-wrap {
            display: block;
            position: relative;
            padding: 40px 40px;
            border-radius: 12px;
            z-index: 0;
            background: #fff;
        }
        
        .testimony-wrap .text {
            position: relative;
            text-align: center;
        }
        
        @media (max-width: 767.98px) {
        .testimony-wrap .text {
            width: 100%;
            padding-top: 10px;
        }
        }
        
        .testimony-wrap .text p {
            color: #000000;
            font-size: 16px;
        }
        
        .testimony-wrap .quote {
            margin: 0px auto 20px;
            opacity: 1;
            width: 40px;
            height: 40px;
            background: #bb3583;
            border-radius: 50%;
        }
        
        @media (max-width: 767.98px) {
        .testimony-wrap .quote {
            top: -140px;
        }
        }
        
        .testimony-wrap .quote i {
            font-size: 20px;
            color: #fff;
        }
        
        .testimony-wrap .name {
            font-weight: 600;
            font-size: 19px;
            margin-bottom: 0;
            color: #000000;
        }
        .swiper-wrapper {
            margin-bottom: 60px;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
        }

        .swiper-pagination-bullet-active {
            background: #fff;
        }

        .swiper-button-prev, .swiper-button-next {
            margin-top: -60px;
            &::after {
                background: #bb3583;
                padding: 10px;
                font-size: 20px;
                border-radius: 12px;
                color: #fff;
            }
        }
    }

    .ministries {
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background: rgb(17, 17, 17);
        background: url('https://i0.wp.com/www.awakencity.church/wp-content/uploads/2020/08/home-connect-bg.png?fit=1920%2C830&ssl=1');
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background: #000;
        background: #fff;
        background: #F1EEE6;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 100px 0px 100px;

        h2, span.subheading {
            color: #000;
        }

        .ministry-item {
            padding: 0px;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 12px;
            .content-container {
                cursor: pointer;
                position: relative;
                background-size: cover;
                background-position: top center;
                padding: 30px;
                min-height: 300px;
                display: flex;
                border-radius: 12px;
                justify-content: center;
                align-items: center;
                width: 100%;
                background-repeat: no-repeat !important;
                .overlays {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    border-radius: 12px;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    content: '';
                    opacity: .4;
                    background: #fff;
                    background: #000;
                }
                .content {
                    z-index: 1;
                    text-align: center;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-end;
                    h6 {
                        color: #fff;
                        font-size: 24px;
                        margin-top: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                    }
        
                    p {
                        color: #fff;
                        font-size: 16px;
                        margin-bottom: 0px;
                    }
        
                    p.schedule {
                        font-size: 16px;
                        margin-bottom: 26px;
                    }
                }
            }
        }

        .btn-main {
            margin-top: 40px;
            background: #000;
            background: linear-gradient(180deg, #333 0%, #000 100%);
            background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
            border: 2px solid #1c589c;
            padding: 14px 25px;
            font-size: 16px;
            font-weight: 500 !important;
            color: #fff;
            transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
            &:hover {
                border-color: #1c589c;
                background: #fff;
                color: #1c589c;
            }
        }
    }
    
    .socials {
        background: #000;
        background: rgb(17, 17, 17);
        background: #185192;
        background-image: repeating-linear-gradient(135deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(45deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(67.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(135deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(45deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(112.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(112.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(45deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(22.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(45deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(22.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(135deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(157.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(67.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(67.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),linear-gradient(90deg, rgb(151, 26, 210),rgb(57, 199, 205));
        background-image: linear-gradient(45deg, rgba(17, 133, 139, 0.26) 0%, rgba(17, 133, 139, 0.26) 16%,rgba(49, 138, 124, 0.26) 16%, rgba(49, 138, 124, 0.26) 30%,rgba(80, 142, 109, 0.26) 30%, rgba(80, 142, 109, 0.26) 51%,rgba(112, 147, 94, 0.26) 51%, rgba(112, 147, 94, 0.26) 66%,rgba(143, 152, 79, 0.26) 66%, rgba(143, 152, 79, 0.26) 77%,rgba(175, 156, 64, 0.26) 77%, rgba(175, 156, 64, 0.26) 98%,rgba(206, 161, 49, 0.26) 98%, rgba(206, 161, 49, 0.26) 100%),linear-gradient(135deg, rgba(3, 50, 197, 0.26) 0%, rgba(3, 50, 197, 0.26) 11%,rgba(19, 69, 202, 0.26) 11%, rgba(19, 69, 202, 0.26) 16%,rgba(34, 88, 207, 0.26) 16%, rgba(34, 88, 207, 0.26) 47%,rgba(50, 107, 213, 0.26) 47%, rgba(50, 107, 213, 0.26) 50%,rgba(66, 126, 218, 0.26) 50%, rgba(66, 126, 218, 0.26) 62%,rgba(81, 145, 223, 0.26) 62%, rgba(81, 145, 223, 0.26) 81%,rgba(97, 164, 228, 0.26) 81%, rgba(97, 164, 228, 0.26) 100%),linear-gradient(90deg, rgb(155, 9, 167) 0%, rgb(155, 9, 167) 41%,rgb(168, 14, 156) 41%, rgb(168, 14, 156) 52%,rgb(182, 18, 144) 52%, rgb(182, 18, 144) 65%,rgb(195, 23, 133) 65%, rgb(195, 23, 133) 75%,rgb(208, 28, 121) 75%, rgb(208, 28, 121) 82%,rgb(222, 32, 110) 82%, rgb(222, 32, 110) 87%,rgb(235, 37, 98) 87%, rgb(235, 37, 98) 100%);
        background: #FFDCAF;
        background: #F1A546;
        padding: 90px 0px;
        h2 {
            color: #000;
        }
        .subheading {
            color: #000;
        }
        a {
            &:hover {
                color: inherit;
            }
        }
        .social-media-item {
            background: #fff;
            text-align: center;
            margin-bottom: 30px;
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 35px 30px 30px;
            border-radius: 12px;
            box-shadow: rgba(124, 124, 124, 0.24) 0px 3px 8px;
            cursor: pointer;
            i {
                font-size: 60px;
                color: #000;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 0px;
                text-transform: capitalize;
            }
            &:hover {
            }
            &.icon-instagram i {
                background: linear-gradient(45deg,#fada49,#ec3323 33%,#b82cb2 66%,#6822dc);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &.icon-youtube i {
                color: #ec3323;
            }
            &.icon-facebook i {
                color: #3d42a2;
            }
            &.icon-podcast i {
                color: #441475;
            }
            &.icon-whatsapp i {
                color: #37BE49;
            }
            &.icon-tiktok i {
                color: #222;
            }
        }
    }
}
