@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonBoldItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonBold.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson Book';
    src: url('FONTSPRINGDEMO-GibsonBookRegular.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonBookRegular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonHeavyRegular.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonHeavyRegular.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonHeavyItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonHeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonLightRegular.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonLightRegular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonThinItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonMediumItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonMediumRegular.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonMediumRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonRegular.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonSemiboldItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonSemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonThinRegular.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonThinRegular.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson Book';
    src: url('FONTSPRINGDEMO-GibsonBookItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonBookItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonLightLightItalic.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonLightLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FONTSPRING DEMO - Gibson';
    src: url('FONTSPRINGDEMO-GibsonSemiboldRegular.woff2') format('woff2'),
        url('FONTSPRINGDEMO-GibsonSemiboldRegular.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

