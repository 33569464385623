// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";



.about {

    .overlay {
        opacity: 0.7 !important;
        background-image: repeating-linear-gradient(135deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),repeating-linear-gradient(45deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),linear-gradient(90deg, rgb(125, 17, 100),rgb(4, 15, 71)) !important;
    }

    .hero-wrap {
        background-image: url('../../assets/images/worship.jpg') !important;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .hero-wrap, .slider-text {
        min-height: 710px !important;
        background-position: center center !important;

        @media (max-width: 979px) {
            min-height: 410px !important;
        }

        h1 {
            margin-top: 80px;
        }
    }

    .bg-section1 {
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) center center / cover, url('../../assets/images/compressed/11.webp');
        padding: 90px 0px !important;
        background: #f2f2f2;
        background: #F1EEE6;

        h2 {
            color: #fff;
            color: #000;
        }
        p {
            color: #fff;
            color: #000;
            font-size: 17px;
        }
        .subheading {
            // color: #fff;
        }

        .text {
            padding: 30px;
        }

        img {
            border-radius: 12px;
        }
    }

    .symbols {
        padding: 120px 0px !important;
        @media (max-width: 768px) {
            padding: 50px 0px !important;
        }
        background: url('../../assets/images/compressed/12.webp');
        background: #fff;
        background: url('https://harvestersng.org/wp-content/uploads/2022/03/time-bg.png');
        background: rgb(17, 17, 17);
        background: #f4f4f4;
        background: #F1EEE6;
        background-repeat: no-repeat;
        background-position: left bottom;

        img {
            border-radius: 12px;
        }

        .side-text {
            margin-bottom: 40px;
            h3 {
                font-weight: 800;
                color: #000;
                line-height: 125%;
            }
            p {
                color: #000;
                font-size: 18px;
                span {
                    font-weight: 600;
                    text-decoration: underline;
                    color: #000;
                    &:hover {
                        color: #441475;
                    }
                }
                b {
                    color: #000;
                }
            }

            .btn-main {
                background: #000;
                margin-top: 20px;
                background: linear-gradient(180deg, #333 0%, #000 100%);
                background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
                border: 2px solid #1c589c;
                padding: 14px 25px;
                font-size: 15px;
                line-height: 27px;
                text-transform: none;
                font-weight: 500 !important;
                color: #fff;
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
                &:hover {
                    border-color: #1c589c;
                    background: #fff;
                    color: #1c589c;
                }
            }
        }
        
        .symbol-item {
            margin-bottom: 30px;
            width: 100%;
            padding: 40px 40px;
            box-shadow: rgba(177, 182, 186, 0.3) 0px 1px 2px 0px, rgba(162, 168, 172, 0.15) 0px 2px 6px 2px;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: #fff;
    
            img {
                margin-bottom: 10px;
                border-radius: 0px;
                width: 50px;
            }
        
            .text {
                width: 100%;
                color: #000;
            }
        
            .text h4 {
                font-size: 16px;
                font-weight: 600;
                color: #000;
                text-transform: uppercase;
                margin-bottom: 0;
            }
        
            .text span.subheading {
                display: block;
                margin-bottom: 0px;
                font-size: 18px;
                font-weight: 600;
                line-height: 150%;
                color: #fff;
                color: #000;
                position: relative;
            }

            p {
                margin-bottom: 0px;
            }
        }

        
    }

    .video-bg {
        background: url('https://harvestersng.org/wp-content/uploads/2022/03/time-bg.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background: url('../../assets/images/compressed/12.webp');
        background: rgb(17, 17, 17);
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background: rgb(22, 40, 70);
        background: #cb2329;
        background-image: linear-gradient(45deg, rgba(183, 110, 7, 0.2) 0%, rgba(183, 110, 7, 0.2) 14%,rgba(148, 103, 21, 0.2) 14%, rgba(148, 103, 21, 0.2) 53%,rgba(113, 96, 34, 0.2) 53%, rgba(113, 96, 34, 0.2) 68%,rgba(79, 90, 48, 0.2) 68%, rgba(79, 90, 48, 0.2) 84%,rgba(44, 83, 61, 0.2) 84%, rgba(44, 83, 61, 0.2) 89%,rgba(9, 76, 75, 0.2) 89%, rgba(9, 76, 75, 0.2) 100%),linear-gradient(135deg, rgba(45, 53, 64, 0.2) 0%, rgba(45, 53, 64, 0.2) 20%,rgba(79, 76, 60, 0.2) 20%, rgba(79, 76, 60, 0.2) 38%,rgba(113, 99, 55, 0.2) 38%, rgba(113, 99, 55, 0.2) 42%,rgba(147, 122, 51, 0.2) 42%, rgba(147, 122, 51, 0.2) 46%,rgba(181, 145, 46, 0.2) 46%, rgba(181, 145, 46, 0.2) 66%,rgba(215, 168, 42, 0.2) 66%, rgba(215, 168, 42, 0.2) 100%),linear-gradient(90deg, rgb(176, 12, 74) 0%, rgb(176, 12, 74) 23%,rgb(190, 31, 76) 23%, rgb(190, 31, 76) 36%,rgb(205, 51, 78) 36%, rgb(205, 51, 78) 41%,rgb(219, 70, 81) 41%, rgb(219, 70, 81) 98%,rgb(234, 90, 83) 98%, rgb(234, 90, 83) 99%,rgb(248, 109, 85) 99%, rgb(248, 109, 85) 100%);
        padding: 100px 0px;

        h2, span, p {
            color: #fff;
        }

        p {
            font-size: 18px;
        }

        .yt-lite {
            border-radius: 12px;
            border: 2px solid #fff;
        }
    }

    .story {
        background: #441475;
        padding: 90px 0px !important;

        h2 {
            color: #fff;
        }

        .heading-section {
            margin-bottom: 50px;
        }

        .subheading {
            color: #f2f2f2;
        }

        p {
            color: #fff;
            font-size: 16px;
            a {
                background-color: #ffffff;
                padding: 15px 25px;
                margin-top: 30px !important;
                border-radius: 12px;
                font-weight: 400;
                font-size: 14px;
                color: #000;
                i {
                    color: #000;
                }
            }
        }

        .images-section {
            margin-bottom: 50px;
            .img-1 {
                padding: 20px;
                transform: rotate(3deg) !important;
            }
            .img-2 {
                padding: 20px;
                // height: 200px;
                transform: rotate(-3deg) !important;
            }
            img {
                height: 400px;
                width: 100%;
                object-fit: cover;
                border-radius: 12px;
                border: 3px solid #fff;
            }
        }
    }

    .tenets {
        background: #F0F0F0;
        background-color: #FFDEE9;
        background-image: linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%);
        background: #FFDCAF;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background: rgb(17, 17, 17);
        background: #000;
        background: #185193;
        background-image: linear-gradient(41deg, rgba(107, 107, 107, 0.04) 0%, rgba(107, 107, 107, 0.04) 8%,rgba(31, 31, 31, 0.04) 8%, rgba(31, 31, 31, 0.04) 100%),linear-gradient(9deg, rgba(228, 228, 228, 0.04) 0%, rgba(228, 228, 228, 0.04) 62%,rgba(54, 54, 54, 0.04) 62%, rgba(54, 54, 54, 0.04) 100%),linear-gradient(124deg, rgba(18, 18, 18, 0.04) 0%, rgba(18, 18, 18, 0.04) 37%,rgba(233, 233, 233, 0.04) 37%, rgba(233, 233, 233, 0.04) 100%),linear-gradient(253deg, rgba(201, 201, 201, 0.04) 0%, rgba(201, 201, 201, 0.04) 55%,rgba(47, 47, 47, 0.04) 55%, rgba(47, 47, 47, 0.04) 100%),linear-gradient(270deg, rgba(172, 172, 172, 0.04) 0%, rgba(172, 172, 172, 0.04) 33%,rgba(26, 26, 26, 0.04) 33%, rgba(26, 26, 26, 0.04) 100%),linear-gradient(64deg, rgba(11, 11, 11, 0.04) 0%, rgba(11, 11, 11, 0.04) 38%,rgba(87, 87, 87, 0.04) 38%, rgba(87, 87, 87, 0.04) 100%),linear-gradient(347deg, rgba(199, 199, 199, 0.04) 0%, rgba(199, 199, 199, 0.04) 69%,rgba(4, 4, 4, 0.04) 69%, rgba(4, 4, 4, 0.04) 100%),linear-gradient(313deg, rgba(36, 36, 36, 0.04) 0%, rgba(36, 36, 36, 0.04) 20%,rgba(91, 91, 91, 0.04) 20%, rgba(91, 91, 91, 0.04) 100%),linear-gradient(90deg, rgb(10, 17, 72),rgb(35, 148, 228));
        background-image: linear-gradient(135deg, rgba(169, 34, 47, 0.26) 0%, rgba(169, 34, 47, 0.26) 7%,rgba(151, 53, 85, 0.26) 7%, rgba(151, 53, 85, 0.26) 23%,rgba(133, 72, 124, 0.26) 23%, rgba(133, 72, 124, 0.26) 33%,rgba(114, 92, 162, 0.26) 33%, rgba(114, 92, 162, 0.26) 63%,rgba(96, 111, 201, 0.26) 63%, rgba(96, 111, 201, 0.26) 90%,rgba(78, 130, 239, 0.26) 90%, rgba(78, 130, 239, 0.26) 100%),linear-gradient(90deg, rgba(23, 178, 123, 0.26) 0%, rgba(23, 178, 123, 0.26) 1%,rgba(39, 182, 133, 0.26) 1%, rgba(39, 182, 133, 0.26) 20%,rgba(56, 185, 143, 0.26) 20%, rgba(56, 185, 143, 0.26) 22%,rgba(72, 189, 154, 0.26) 22%, rgba(72, 189, 154, 0.26) 27%,rgba(89, 192, 164, 0.26) 27%, rgba(89, 192, 164, 0.26) 56%,rgba(105, 196, 174, 0.26) 56%, rgba(105, 196, 174, 0.26) 100%),linear-gradient(90deg, rgb(19, 41, 73) 0%, rgb(19, 41, 73) 1%,rgb(23, 37, 95) 1%, rgb(23, 37, 95) 19%,rgb(27, 33, 118) 19%, rgb(27, 33, 118) 27%,rgb(31, 29, 140) 27%, rgb(31, 29, 140) 53%,rgb(36, 26, 163) 53%, rgb(36, 26, 163) 64%,rgb(40, 22, 185) 64%, rgb(40, 22, 185) 79%,rgb(44, 18, 208) 79%, rgb(44, 18, 208) 83%,rgb(48, 14, 230) 83%, rgb(48, 14, 230) 100%);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 90px 0px;
        @media (max-width: 768px) {
            padding: 50px 0px !important;
        }
        h2 {
            color: #fff;
        }
        .subheading {
            color: #fff;
        }
        .tenets-item {
            border: 2px solid #fff;
            padding: 25px;
            border-radius: 12px;
            gap: 15px;
            margin-bottom: 40px;
            h1 {
                line-height: 100%;
                font-weight: 900;
                font-size: 30px;
                color: #fff;
            }
            h5 {
                color: #fff;
            }

            p {
                color: #fff;
                margin-bottom: 0px;
            }
        }

        .swiper {
            // padding-bottom: 90px;
        }

        .swiper-pagination {
            position: relative;
        }

        .swiper-pagination-bullet {
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            font-size: 12px;
            color: #fff;
            opacity: 1;
            background: rgba(0, 0, 0, 0.9);
            background: #f2f2f2;
            color: #000;
            margin-bottom: 10px;
        }
          
          .swiper-pagination-bullet-active {
            color: #fff;
            background: #000;
          }
    }

    .pastors-section {
        background: #E9C13B;
        background-image: linear-gradient(45deg, rgba(243, 229, 17, 0.27) 0%, rgba(243, 229, 17, 0.27) 4%,rgba(238, 212, 29, 0.27) 4%, rgba(238, 212, 29, 0.27) 20%,rgba(233, 194, 40, 0.27) 20%, rgba(233, 194, 40, 0.27) 31%,rgba(228, 177, 52, 0.27) 31%, rgba(228, 177, 52, 0.27) 51%,rgba(223, 159, 64, 0.27) 51%, rgba(223, 159, 64, 0.27) 64%,rgba(218, 142, 76, 0.27) 64%, rgba(218, 142, 76, 0.27) 67%,rgba(213, 124, 87, 0.27) 67%, rgba(213, 124, 87, 0.27) 88%,rgba(208, 107, 99, 0.27) 88%, rgba(208, 107, 99, 0.27) 100%),linear-gradient(135deg, rgba(238, 114, 87, 0.27) 0%, rgba(238, 114, 87, 0.27) 4%,rgba(220, 101, 92, 0.27) 4%, rgba(220, 101, 92, 0.27) 6%,rgba(202, 89, 98, 0.27) 6%, rgba(202, 89, 98, 0.27) 40%,rgba(184, 76, 103, 0.27) 40%, rgba(184, 76, 103, 0.27) 66%,rgba(166, 64, 109, 0.27) 66%, rgba(166, 64, 109, 0.27) 87%,rgba(148, 51, 114, 0.27) 87%, rgba(148, 51, 114, 0.27) 100%),linear-gradient(0deg, rgb(133, 68, 44) 0%, rgb(133, 68, 44) 8%,rgb(153, 87, 54) 8%, rgb(153, 87, 54) 27%,rgb(173, 106, 63) 27%, rgb(173, 106, 63) 59%,rgb(192, 125, 73) 59%, rgb(192, 125, 73) 61%,rgb(212, 144, 82) 61%, rgb(212, 144, 82) 82%,rgb(232, 163, 92) 82%, rgb(232, 163, 92) 100%);
        background-image: linear-gradient(109deg, rgba(75, 75, 75, 0.03) 0%, rgba(75, 75, 75, 0.03) 36%,rgba(5, 5, 5, 0.03) 36%, rgba(5, 5, 5, 0.03) 60%,rgba(230, 230, 230, 0.03) 60%, rgba(230, 230, 230, 0.03) 76%,rgba(204, 204, 204, 0.03) 76%, rgba(204, 204, 204, 0.03) 83%,rgba(30, 30, 30, 0.03) 83%, rgba(30, 30, 30, 0.03) 91%,rgba(119, 119, 119, 0.03) 91%, rgba(119, 119, 119, 0.03) 96%,rgba(27, 27, 27, 0.03) 96%, rgba(27, 27, 27, 0.03) 100%),linear-gradient(357deg, rgba(159, 159, 159, 0.03) 0%, rgba(159, 159, 159, 0.03) 7%,rgba(227, 227, 227, 0.03) 7%, rgba(227, 227, 227, 0.03) 26%,rgba(204, 204, 204, 0.03) 26%, rgba(204, 204, 204, 0.03) 28%,rgba(181, 181, 181, 0.03) 28%, rgba(181, 181, 181, 0.03) 34%,rgba(138, 138, 138, 0.03) 34%, rgba(138, 138, 138, 0.03) 36%,rgba(44, 44, 44, 0.03) 36%, rgba(44, 44, 44, 0.03) 74%,rgba(130, 130, 130, 0.03) 74%, rgba(130, 130, 130, 0.03) 100%),linear-gradient(132deg, rgba(191, 191, 191, 0.03) 0%, rgba(191, 191, 191, 0.03) 24%,rgba(174, 174, 174, 0.03) 24%, rgba(174, 174, 174, 0.03) 31%,rgba(19, 19, 19, 0.03) 31%, rgba(19, 19, 19, 0.03) 42%,rgba(242, 242, 242, 0.03) 42%, rgba(242, 242, 242, 0.03) 70%,rgba(131, 131, 131, 0.03) 70%, rgba(131, 131, 131, 0.03) 75%,rgba(167, 167, 167, 0.03) 75%, rgba(167, 167, 167, 0.03) 77%,rgba(64, 64, 64, 0.03) 77%, rgba(64, 64, 64, 0.03) 100%),linear-gradient(90deg, rgb(245, 198, 84),rgb(209, 69, 18));
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 90px 0px;
        @media (max-width: 768px) {
            padding: 50px 0px !important;
        }

        .subheading {
            color: #000;
        }

        .staff {
            width: 100%;
            margin-bottom: 30px;
            
            .img {
                background-position: top center;
                border-radius: 12px;
                width: 100%;
                display: block;
                height: 290px;
                border: 5px solid #fff;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
                box-shadow: rgba(50, 50, 93, 0.15) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            }

            .text {
                padding: 10px 10px;
                width: 80%;
                margin: 0 auto;
                text-align: center;
                margin-top: -20px;
                border-radius: 12px;
                // border: 2px solid #fff;
                background: #f8d562;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }

            .text h3 {
                color: #000;
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 600;
            }

            .text span {
                color: rgba(255, 255, 255, 0.7);
            }

            &:hover .text {
                width: 90%;
            }
        }
    }

    .council-section {
        background: url('../../assets/images/compressed/12.webp');
        background-image: linear-gradient(45deg, rgba(183, 139, 215, 0.45) 0%, rgba(183, 139, 215, 0.45) 24%,rgba(162, 124, 192, 0.45) 24%, rgba(162, 124, 192, 0.45) 41%,rgba(141, 109, 170, 0.45) 41%, rgba(141, 109, 170, 0.45) 62%,rgba(121, 95, 147, 0.45) 62%, rgba(121, 95, 147, 0.45) 68%,rgba(100, 80, 124, 0.45) 68%, rgba(100, 80, 124, 0.45) 74%,rgba(79, 65, 102, 0.45) 74%, rgba(79, 65, 102, 0.45) 88%,rgba(58, 50, 79, 0.45) 88%, rgba(58, 50, 79, 0.45) 100%),linear-gradient(90deg, rgb(131, 4, 71) 0%, rgb(131, 4, 71) 1%,rgb(142, 9, 101) 1%, rgb(142, 9, 101) 14%,rgb(153, 15, 130) 14%, rgb(153, 15, 130) 21%,rgb(165, 20, 160) 21%, rgb(165, 20, 160) 36%,rgb(176, 25, 189) 36%, rgb(176, 25, 189) 73%,rgb(187, 31, 219) 73%, rgb(187, 31, 219) 77%,rgb(198, 36, 248) 77%, rgb(198, 36, 248) 100%);
        background: #a92a74;
        background: #F1EEE6;
        background-size: cover;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 768px) {
            padding: 50px 0px !important;
        }

        h2, .subheading {
            color: #000;
        }

        .staff .text {
            background: #CC4694;
            background: #fff;
            margin-top: -40px;
            border-radius: 12px;
            h3, span {
                color: #000;
            }
        }
    }

    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -30px; /* gutter size offset */
        width: auto;
    }
    .my-masonry-grid_column {
        padding-left: 30px; /* gutter size */
        background-clip: padding-box;
    }

    /* Style your items */
    .my-masonry-grid_column > div {
        margin-bottom: 30px;
    }
}
