// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";



.ministries {

    .overlay {
        opacity: 0.7 !important;
        background-image: repeating-linear-gradient(135deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),repeating-linear-gradient(45deg, rgba(172, 176, 216,0.13) 0px, rgba(172, 176, 216,0.13) 1px,transparent 1px, transparent 9px),linear-gradient(90deg, rgb(125, 17, 100),rgb(4, 15, 71)) !important;
    }

    .hero-wrap {
        background-image: url('../../assets/gallery/OtherDepartments/ministry.JPG');
    }

    .hero-wrap, .slider-text {
        min-height: 710px !important;
        background-position: top center;

        @media (max-width: 979px) {
            min-height: 410px !important;
        }
    }

    .ministries-list {
        padding-bottom: 40px;
        
        .ministry {
            margin-bottom: 80px;
            border-radius: 12px;
            position: relative;

            &.flex-row-reverse {
                .image {
                    transform: rotate(-1deg) !important;
                }
            }

            button.btn-main {
                padding: 12px 15px;
                font-size: 15px;
                border: 1px solid #000;
                text-transform: none;
                background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
                border: 2px solid #1c589c;
                font-weight: 500 !important;
                color: #fff;
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
                &:hover {
                    border-color: #1c589c;
                    background: #fff;
                    color: #1c589c;
                }
            }
        }

        .ministry .image {
            padding: 15px;
            border-radius: 12px;
            transform: rotate(1deg) !important;
            background: #1c589c;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    
        .ministry img {
            width: 100%;
            border-radius: 12px;
        }
    
        .ministry .text {
            width: 100%;
            border-radius: 12px;
            padding: 60px;

            h2 {
                color: #000;
                font-size: 36px;
                font-weight: 900;
                line-height: normal;
                margin-bottom: 28px;
            }
    
            a {
                color: #3C4094;
                font-size: 16px;
                font-weight: 500;
                line-height: 140%;
                text-decoration-line: underline;
            }

            p {
                font-size: 17px;
                color: #000;
                margin-bottom: 32px;
            }

            @media (max-width: 568px) {
                padding: 60px 10px 0px;
            }
        }

    }

    .departments {
        background: #F1EEE6;
        padding: 90px 0px;

        .heading-section {
            margin-bottom: 80px;
        }

        .subheading {
            color: #000;
        }

        .department-item {
            margin-bottom: 30px;
            background: #ffff;
            display: flex;
            // align-items: flex-end;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 32px;
            padding: 20px;
            border-radius: 12px;
            position: inherit;
            background-position: center center;
            overflow: hidden;
            box-shadow: rgba(124, 124, 124, 0.14) 0px 3px 8px;

            img {
                margin-bottom: 20px;
                border-radius: 8px;
                height: 200px;
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
            h6 {
                color: #000;
                font-size: 18px;
                font-style: normal;
                font-weight: 900;
                line-height: 150%;
            }

            p {
                color: #000;
                font-size: 14px;
                margin-bottom: 0px;
            }
        }
        .btn-main {
            background: #000;
            margin-top: 20px;
            background: linear-gradient(180deg, #333 0%, #000 100%);
            background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
            border: 2px solid #1c589c;
            padding: 14px 25px;
            font-size: 15px;
            line-height: 27px;
            text-transform: none;
            font-weight: 500 !important;
            color: #fff;
            transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
            &:hover {
                border-color: #1c589c;
                background: #fff;
                color: #1c589c;
            }
        }
    }

    .wellspring-bg {
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) bottom center / cover, url('../../assets/images/compressed/11.webp');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 90px 0px;

        p {
            color: #fff;
            font-size: 17px;
            margin-bottom: 30px;
        }

        img {
            border-radius: 12px;
            border: 2px solid #fff;
        }
    }

    .ministry-description {
        padding: 100px 0px;
        background: #F1EEE6;
        h2 {
            // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 30px;
            font-weight: 600;
            color: #203d6a;
        }

        h3 {
            color: #203d6a;
            font-size: 20px;
            font-weight: 500;
        }

        p {
            color: rgb(58, 66, 74);
            font-size: 16px;
            margin-bottom: 32px;
        }

        hr {
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 24px;
        }

        ul {
            padding-left: 15px;
            position: relative;
            li {
                color: #000;
                line-height: 40px;
                list-style-type: none;
                color: rgb(58, 66, 74);
                font-size: 16px;
                padding-left: 20px;
                &:before {
                    content: '⦿';
                    position: absolute;
                    left: 0;
                }
            }
        }

        .btn-main {
            background: #000;
            margin-top: 20px;
            background: linear-gradient(180deg, #333 0%, #000 100%);
            background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
            border: 2px solid #1c589c;
            padding: 10px 25px;
            font-size: 15px;
            line-height: 27px;
            text-transform: none;
            font-weight: 500 !important;
            color: #fff;
            transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
            &:hover {
                border-color: #1c589c;
                background: #fff;
                color: #1c589c;
            }
        }

        .join-ministry {
            // border: 1px solid rgb(54, 101, 176);
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            padding: 30px;
            background: #fff;
            border-radius: 12px;
            h5 {
                margin-bottom: 30px;
                font-size: 24px;
                font-weight: 500;
            }
            .label-text {
                color: #A0A8B0;
                font-size: 14px;
                margin-bottom: 8px;
            }
            .border-top-down {
                border-top: 1px solid rgba(223, 226, 229, 0.65);
                border-bottom: 1px solid rgba(223, 226, 229, 0.65);
                margin-bottom: 20px;
                padding: 20px 0px;
            }
            .contact-info {
                border-radius: 12px;
                background: #F9F9F9;
                padding: 20px;

                p {
                    font-size: 15px;
                    line-height: 150%;
                    margin-bottom: 10px;
                }
            }
            .social-media {
                display: flex;
                // justify-content: space-between;
                gap: 30px;
            }
            a {
                &:hover {
                    color: #000;
                }
            }
            i {
                color: #000;
                font-size: 30px;
            }
            .btn-main {
                background: #000;
                margin-top: 20px;
                background: linear-gradient(180deg, #333 0%, #000 100%);
                background: linear-gradient(180deg, #2766AD 0%, #1c589c 100%);
                border: 2px solid #1c589c;
                padding: 14px 25px;
                font-size: 15px;
                line-height: 27px;
                text-transform: none;
                width: 100%;
                font-weight: 500 !important;
                color: #fff;
                transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
                &:hover {
                    border-color: #1c589c;
                    background: #fff;
                    color: #1c589c;
                }
            }
        }
    }
}
