// @tailwind base;
// @tailwind components;
@tailwind utilities;
// @import "../../../node_modules/antd/dist/antd.css";
@import "../../scss/main.scss";
@import "../../assets/fonts/nuckle/stylesheet.css";
@import "../../assets/fonts/robert-sans/style.css";
@import "../../assets/fonts/hero-new/style.css";



.resources {
    .overlay {
        opacity: .5 !important;
        background: #000 !important;
    }

    .hero-wrap {
        background: linear-gradient(rgba(2, 14, 4, 0.35), rgba(0, 0, 0, 0.87)) center center / cover, url('../../assets/images/cta.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .hero-wrap, .slider-text {
        min-height: 500px;
        background-position: bottom center;
    }

    
    .upcoming-event {
        padding: 60px 0px;
        .event-item {
            margin-bottom: 50px;
            img {
                border-radius: 12px;
            }

            h5 {
                color: #1D3451;
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}